import styled from "styled-components";
import backgroundPhoto from "../assets/Excevator.jpg";
import logoImg from "../assets/Full-logo-white.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Wrapper>
      <div className="hero-wrapper">
        <div className="hero-menu">
          <p>
            <motion.span
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{
                duration: "0.75",
                delay: 2.5,
              }}
              className="menu-one"
            >
              <img src={logoImg} alt="logo" className="hero-logo" />
            </motion.span>{" "}
            <Link to="/o-nama" className="link">
              <motion.span
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                transition={{
                  duration: "0.75",
                  delay: 2.5,
                }}
                className="menu-two"
              >
                O nama
              </motion.span>{" "}
            </Link>
            <Link to="/usluge" className="link">
              <motion.span
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{
                  duration: "0.75",
                  delay: 2.5,
                }}
                className="menu-three"
              >
                Usluge
              </motion.span>{" "}
            </Link>
            <Link to="/kontakt" className="link">
              <motion.span
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                transition={{
                  duration: "0.75",
                  delay: 2.5,
                }}
                className="menu-four"
              >
                Kontakt
              </motion.span>
            </Link>
          </p>
        </div>
      </div>
      <div className="hero-main">
        <p className="hero-txt">
          OTKRIJTE BUDUĆNOST EKSPLOATACIJE PIJESKA I ŠLJUNKA
        </p>
      </div>
      <img src={backgroundPhoto} alt="Bager.jpg" className="bcgImg" />
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.div`
  position: relative;

  .bcgImg {
    height: 100vh;
    width: 100vw;
    filter: brightness(60%);
  }
  .link {
    text-decoration: none;
    color: white;
  }
  .hero-wrapper {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }
  .hero-menu {
    z-index: 999;
    height: 75vh;

    p {
      text-align: center;
      color: white;
      font-size: 1.5rem;
      line-height: 5rem;
    }
    .menu-one {
      position: absolute;
      top: 0%;
      left: 0%;
      margin-left: 15rem;
      cursor: pointer;
    }
    .hero-logo {
      width: 6rem;
      height: 6rem;
    }
    .menu-two {
      position: absolute;
      top: 0%;
      right: 0%;
      margin-right: 15rem;
      cursor: pointer;
    }
    .menu-two:hover {
      text-decoration: underline;
    }
    .menu-three {
      position: absolute;
      bottom: 0%;
      left: 0%;
      margin-left: 15rem;
      cursor: pointer;
    }
    .menu-three:hover {
      text-decoration: underline;
    }
    .menu-four {
      position: absolute;
      right: 0%;
      bottom: 0%;
      margin-right: 15rem;
      cursor: pointer;
      color: white;
    }
    .menu-four:hover {
      text-decoration: underline;
    }
  }
  .hero-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  .hero-txt {
    text-align: center;
    color: white;
    font-size: 4rem;
    line-height: 5rem;
    white-space: pre-wrap;
  }
  @media only screen and (max-height: 650px) {
    .hero-main {
      p {
        font-size: 2.5rem;
      }
    }
  }
  @media only screen and (max-width: 650px) and (min-width: 451px) {
    .hero-txt {
      font-size: 2.75rem;
      line-height: 4rem;
    }
    .menu-one,
    .menu-two,
    .menu-three,
    .menu-four {
      font-size: 1.25rem;
    }
    .bcgImg {
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    .menu-one,
    .menu-three {
      margin-left: 7rem !important;
    }
    .menu-two,
    .menu-four {
      margin-right: 7rem !important;
    }
  }
  @media only screen and (max-width: 450px) {
    .menu-one,
    .menu-two,
    .menu-three,
    .menu-four {
      font-size: 1.1rem;
    }
    .hero-txt {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .bcgImg {
      object-fit: cover;
    }
    .menu-one,
    .menu-three {
      margin-left: 1rem !important;
    }
    .menu-two,
    .menu-four {
      margin-right: 1rem !important;
    }
  }
`;
