import { useEffect } from "react";
import styled from "styled-components";
import SkewLoader from "react-spinners/SkewLoader";

const Loader = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Wrapper>
      <div className="giga-container">
        <div className={`loader-container`}>
          <SkewLoader
            color="#fff"
            cssOverride={{}}
            loading={true}
            size={45}
            speedMultiplier={1}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .giga-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 500vh;
    width: 350vw;
    background-color: #6b654c;
    z-index: 99999;
  }
  .loader-container {
    position: absolute;
    right: 0;
    top: 0;
    background: #6b654c;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  @media screen and (max-width: 1010px) {
    .giga-container {
      height: 900vh;
    }
  }
`;
export default Loader;
