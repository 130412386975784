import { styled } from "styled-components";

const ActualFooter = () => {
  return (
    <Wrapper>
      <p className="footer-txt">
        © {new Date().getFullYear()} ROMIĆ-PROMET d.o.o. Sva prava pridržana.
      </p>
    </Wrapper>
  );
};

export default ActualFooter;

const Wrapper = styled.div`
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-txt {
    color: white;
    font-family: "Martian Mono", monospace;
    text-align: center;
    font-size: 1.3rem;
    padding: 0 2rem;
  }
  @media only screen and (max-width: 550px) {
    .footer-txt {
      font-size: 1.1rem;
    }
  }
`;
