import { styled } from "styled-components";

const Text = () => {
  return (
    <Wrapper>
      <div className="text-wrapper">
        <div className="text-left">
          Stručnost eksploatacije pijeska i šljunka
        </div>
        <div className="text-right">
          {" "}
          Doživite novu eru u proizvodnji građevinskog materijala uz
          ROMIĆ-PROMET. Naše ovladavanje vrhunskim alatima mijenja način na koji
          vadimo, obrađujemo i transportiramo pijesak osnažujući renomirane
          infrastrukturne projekte.
        </div>
      </div>
    </Wrapper>
  );
};

export default Text;

const Wrapper = styled.div`
  position: relative;

  .text-wrapper {
    position: relative;
    padding: 8rem 15rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    margin-bottom: 7vh;
  }
  .text-left {
    color: white;
    font-size: 2.5rem;
  }
  .text-right {
    color: white;
    font-size: 1.2rem;
    line-height: 1.85rem;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    .text-wrapper {
      padding: 4rem 7rem;
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
      grid-row-gap: 2rem;
    }
    .text-left {
      font-size: 2rem;
    }
    .text-right {
    }
  }
  @media only screen and (max-width: 450px) {
    .text-wrapper {
      padding: 4rem 1rem;
      grid-template-columns: 1fr;
      grid-template-rows: 2fr;
      grid-row-gap: 1rem;
    }
    .text-left {
      font-size: 1.7rem;
    }
  }
`;
