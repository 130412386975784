import styled from "styled-components";
import Navbar from "../components/Navbar";
const Contact = () => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <div className="text-component-wrapper">
          <div className="team-cubes-wrapper">
            <div className="cube center">
              <p className="persons-job">Uprava</p>
              <p className="persons-name">ZVONIMIR ŠKORIĆ</p>
              <p className="persons-name">zvonimir.skoric@prodorina.hr</p>
            </div>
            <div className="cube center">
              <p className="persons-job">Uprava</p>
              <p className="persons-name">BRANKO FERENČEVIĆ</p>
              <p className="persons-name">branko.ferencevic@prodorina.hr</p>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  padding: 5rem 15rem 0rem 15rem;
  margin-bottom: 10rem;
  .team-component-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .team-cubes-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .cube {
    height: 305px;
    background-color: rgb(122, 115, 87);
    color: white;
    border-radius: 40px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .persons-name {
    font-size: 1.2rem;
    padding-bottom: 0.2rem;
  }
  .persons-job {
    font-size: 1.1rem;
    padding-bottom: 2.2rem;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    padding: 3rem 7rem;
    padding-right: 0rem;
    padding-left: 0rem;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  @media only screen and (max-width: 870px) {
    .team-cubes-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 1.5rem;
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 3rem 0.8rem !important;
  }
`;
export default Contact;
