import styled from "styled-components";
import Navbar from "../components/Navbar";

const AboutUs = () => {
  return (
    <Wrapper>
      <Navbar />
    </Wrapper>
  );
};
const Wrapper = styled.div``;
export default AboutUs;
