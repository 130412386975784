import { styled } from "styled-components";
import backgroundPhoto from "../assets/Hills-modified.jpg";

const Quote = () => {
  return (
    <Wrapper>
      <img src={backgroundPhoto} alt="Bager.jpg" className="bcgImg" />
      <div className="overlay"></div>
      <p className="quote-text">STVARAMO KRAJOLIK SUTRAŠNJICE.</p>
    </Wrapper>
  );
};

export default Quote;

const Wrapper = styled.div`
  position: relative;
  height: 95vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .bcgImg {
    position: absolute;
    height: 95vh;
    width: 100vw;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    height: 95vh;
    width: 100vw;
    background-color: rgb(122, 115, 87);
    opacity: 0.7;
  }
  .quote-text {
    position: absolute;
    text-align: center;
    color: white;
    font-size: 5rem;
    padding: 0rem 15rem;
    z-index: 1000;
  }
  @media only screen and (max-width: 1100px) and (min-width: 871px) {
    height: 305px;
    .bcgImg {
      height: 305px;
    }
    .overlay {
      height: 305px;
    }
    .quote-text {
      font-size: 2.5rem;
      padding: 0rem 7rem;
    }
  }
  @media only screen and (max-width: 870px) and (min-width: 451px) {
    height: 305px;
    .bcgImg {
      height: 305px;
    }
    .overlay {
      height: 305px;
    }
    .quote-text {
      font-size: 2.5rem;
    }
  }
  @media only screen and (max-width: 450px) {
    height: 305px;
    .bcgImg {
      height: 305px;
    }
    .overlay {
      height: 305px;
    }
    .quote-text {
      padding: 0rem 1.5rem;
      font-size: 1.5rem;
    }
  }
`;
