import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUserCheck } from "@fortawesome/free-solid-svg-icons";
const Metrics = () => {
  return (
    <Wrapper>
      <div className="metrics-wrapper">
        <div className="big-metric center">
          <p>
            <span className="metric-number">500 +</span> <br /> Završenih
            projekata
          </p>
        </div>
        <div className="small-metric center">
          <FontAwesomeIcon icon={faCheck} className="icon" />
        </div>
        <div className="small-metric center">
          <FontAwesomeIcon icon={faUserCheck} className="icon" />
        </div>
        <div className="big-metric center">
          <p>
            <span className="metric-number">98 %</span> <br /> Zadovoljstvo
            klijenata
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Metrics;

const Wrapper = styled.div`
  padding: 0rem 15rem;
  margin-bottom: 10rem;
  .metrics-wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-row-gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .small-metric-position {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  .big-metric {
    background-color: rgb(122, 115, 87);
    border-radius: 3rem;
    height: 37vh;
    p {
      color: white;
      font-size: 1.6rem;
    }
  }
  .metric-number {
    font-size: 5rem;
  }
  .small-metric {
    background-color: rgb(122, 115, 87);
    height: 37vh;
    border-radius: 3rem;
  }
  .icon {
    transform: scale(9);
    color: white;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    padding: 0rem 7rem;
  }
  @media only screen and (max-width: 450px) {
    padding: 0rem 1.5rem;
  }
  @media only screen and (max-width: 870px) {
    .metric-number {
      padding-left: 1.5rem;
    }
  }
`;
