import styled from "styled-components";
import logoImg from "../assets/Full-logo-white.svg";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <Wrapper>
      <div className="nav-container">
        <div>
          <Link to="/">
            <img src={logoImg} alt="logo" className="hero-logo" />
          </Link>
        </div>
        <Link to="/usluge" className="navbar-link link">
          Usluge
        </Link>
        <Link to="/o-nama" className="navbar-link link">
          O nama
        </Link>
        <Link to="/inovacija" className=" navbar-link link">
          Inovacija
        </Link>
        <Link to="/kontakt" className="navbar-link link">
          Kontakt
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 10rem;
  padding: 2rem 15rem 2rem 15rem;
  .hero-logo {
    width: 6rem;
    height: 6rem;
    cursor: pointer;
  }
  .nav-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .navbar-link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: bold;
  }
  .navbar-link:hover {
    text-decoration: underline;
  }
  .link {
    text-decoration: none;
    color: white;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    padding-left: 7rem;
    padding-right: 7rem;
    .nav-container {
      display: flex;
      justify-content: center;
    }
    .hero-logo {
      height: 8.5rem;
      width: 8.5rem;
    }
    .navbar-link {
      display: none;
    }
  }
  @media only screen and (max-width: 450px) {
    padding-left: 1rem;
    padding-right: 1rem;
    .nav-container {
      display: flex;
      justify-content: center;
    }
    .hero-logo {
      height: 8.5rem;
      width: 8.5rem;
    }
    .navbar-link {
      display: none;
    }
  }
`;

export default Navbar;
