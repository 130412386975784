import { styled } from "styled-components";

const Team = () => {
  return (
    <Wrapper>
      <div className="text-component-wrapper">
        <div className="text-header">Uprava</div>
        <div className="team-cubes-wrapper">
          <div className="cube center">
            <p className="persons-name">ZVONIMIR ŠKORIĆ</p>
            <p className="persons-name">zvonimir.skoric@prodorina.hr</p>
          </div>
          <div className="cube center">
            <p className="persons-name">BRANKO FERENČEVIĆ</p>
            <p className="persons-name">branko.ferencevic@prodorina.hr</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Team;

const Wrapper = styled.div`
  padding: 0rem 15rem;
  margin-bottom: 10rem;
  .text-header {
    color: white;
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 2.8rem;
  }
  .team-component-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .team-cubes-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .cube {
    height: 305px;
    background-color: rgb(122, 115, 87);
    color: white;
    border-radius: 40px;
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .persons-name {
    font-size: 1.2rem;
    padding-bottom: 0.2rem;
  }
  .persons-job {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    padding: 0rem 7rem;
  }
  @media only screen and (max-width: 870px) {
    margin-bottom: 5rem;
    .team-cubes-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 1.5rem;
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 0rem 1.5rem;
  }
`;
