import styled from "styled-components";
import ActualFooter from "./components/ActualFooter";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Inovation from "./pages/Inovation";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <Wrapper className="App">
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="/inovacija" element={<Inovation />} />
        <Route path="/usluge" element={<Services />} />
        <Route path="/o-nama" element={<AboutUs />} />
        <Route path="/kontakt" element={<Contact />} />
      </Routes>
      <ActualFooter />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
export default App;
