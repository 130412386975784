import styled from "styled-components";
import Navbar from "../components/Navbar";
const Inovation = () => {
  return (
    <Wrapper>
      <Navbar />
      <div className="text-header">
        Romić Promet - Vaš Pouzdani Partner za Eksploataciju Pijeska i Šljunka
      </div>
      <div className="text-header-description">
        U srcu našeg poslovanja je strast prema visokokvalitetnom pijesku i
        predanost pružanju najboljih resursa za vaše građevinske potrebe. Mi smo
        lideri u industriji eksploatacije pijeska, posvećeni unapređenju
        infrastrukture i podršci održivim građevinskim projektima.
      </div>
      <div className="bulletpoint-header">Naša temeljna načela</div>
      <div className="bulletpoint-container">
        <div className="bulletpoint">
          <span className="bold">Kvaliteta Bez Kompromisa:</span> Naša predanost
          vrhunskoj kvaliteti pijeska je ono što nas izdvaja. naš tim stručnjaka
          pažljivo bira najčišći i najprikladniji materijal, osiguravajući da
          svako zrno zadovolji najstrože standarde kvalitete.
        </div>
        <div className="bulletpoint">
          <span className="bold">Pouzdano Snabdijevanje:</span>Razumijemo
          hitnost vaših projekata. Naša efikasna logistika i pouzdane isporuke
          garantiraju da ćete imati potreban pijesak na vrijeme, svaki put.
        </div>
        <div className="bulletpoint">
          <span className="bold">Stručnost i Iskustvo:</span>
          Naš tim stručnjaka ima bogato iskustvo u industriji i spreman je
          pružiti savjete i podršku u svim fazama vašeg projekta.
        </div>
        <div className="bulletpoint">
          <span className="bold">Inovacija u Praksi:</span>
          Ulaganjem u najnovije tehnologije, mi kontinuirano unapređujemo naše
          procese eksploatacije, osiguravajući efikasnost, preciznost i visoke
          standarde proizvoda.
        </div>
        <div className="bulletpoint">
          <span className="bold">Održivost i Ekologija:</span>
          Svjesni važnosti očuvanja prirode, ulažemo napore u održivu
          eksploataciju pijeska. Naše metode su ekološki prihvatljive, čuvajući
          okolinu za buduće generacije.
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: white;
  .bold {
    font-weight: 900;
    padding-right: 0.6rem;
  }
  .text-header {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    padding: 4rem 25rem 4rem 15rem;
  }
  .text-header-description {
    font-size: 1.1rem;
    padding: 0rem 15rem 4rem 15rem;
  }
  .bulletpoint-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-row-gap: 6rem;
    grid-column-gap: 3rem;
    padding-left: 15rem;
    padding-right: 15rem;
    padding-bottom: 7rem;
  }
  .bulletpoint-header {
    padding: 4rem 25rem 4rem 15rem;
    font-size: 1.35rem;
  }
  .bulletpoint {
    font-size: 1.1rem;
    font-weight: 100;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    .text-header {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    .text-header-description {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    .bulletpoint-header {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    .bulletpoint-container {
      padding-left: 7rem;
      padding-right: 7rem;
      padding-bottom: 4.5rem;
    }
  }
  @media only screen and (max-width: 450px) {
    .text-header {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .text-header-description {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .text-header-description {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .bulletpoint-header {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .bulletpoint-container {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
    }
  }
`;
export default Inovation;
