import { styled } from "styled-components";
import excevatorPhoto from "../assets/Excevator-two.jpg";
import sandPhoto from "../assets/Sand.jpeg";
import truckPhoto from "../assets/Truck.jpg";
const Gallery = () => {
  return (
    <Wrapper>
      <div className="main-div">
        <div className="txt-img">
          <img src={excevatorPhoto} alt="Bager" className="gallery-photo" />
          <div className="text-container">
            <p className="image-header">Moderni strojevi</p>
            <p className="image-text">
              Naš tim opremamo najsuvremenijim strojevima, osiguravajući
              maksimalnu učinkovitost i sjajne rezultate.
            </p>
          </div>
        </div>
        <div className="txt-img">
          <img src={sandPhoto} alt="Pijesak" className="gallery-photo" />
          <div className="text-container">
            <p className="image-header">Preciznost obrade</p>
            <p className="image-text">
              Naše vješte ekipe osiguravaju visoku razinu preciznosti u
              prozivodnji.
            </p>
          </div>
        </div>
        <div className="txt-img">
          <img src={truckPhoto} alt="Pijesak" className="truck-photo" />
          <div className="text-container">
            <p className="image-header">Regionalni doseg</p>
            <p className="image-text">
              Ponosno služeći regionalnu klijentelu, izgradili smo reputaciju
              premašivši sva očekivanja
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Gallery;

const Wrapper = styled.div`
  padding: 0rem 15rem;
  padding-top: 0rem;
  margin-bottom: 10rem;
  position: relative;
  .main-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .gallery-photo {
    height: 305px;
    width: 100%;
    object-fit: fill;
    border-radius: 40px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    text-align: left;
  }
  .truck-photo {
    height: 305px;
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    text-align: left;
  }
  .txt-img {
    /* background-color: white; */
    position: relative;
    display: flex;
    align-items: left;
    flex-direction: column;
  }
  .text-container {
    width: 100%;
  }
  .image-header {
    color: White;
    margin: 1.5rem 0rem;
    font-size: 1.2rem;
  }
  .image-text {
    color: white;
    margin: 1.5rem 0rem;
    font-size: 1.2rem;
    line-height: 1.85rem;
  }
  @media only screen and (max-width: 1100px) and (min-width: 451px) {
    padding: 0rem 7rem;
  }
  @media only screen and (max-width: 870px) {
    .main-div {
      display: grid;
      grid-template-columns: 1fr;
    }
    .gallery-photo {
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 0rem 1.5rem;
  }
`;
